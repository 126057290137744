import axios from "axios";

const INITIAL_STATE = {
  id: undefined,
  name: "",
  organization: "",
  title: "",
  email: "",
  emailFlag: false,
  instagram: "",
  facebook: "",
  x: "",
  linkedin: "",
  profilePicture: "",
  formName: "",
  formOrganization: "",
  formTitle: "",
  formEmail: "",
  formEmailFlag: false,
  formInstagram: "",
  formFacebook: "",
  formX: "",
  formLinkedIn: "",
  formPicture: null,
  form64Picture: "",
  selectedPicture: "",
  uploadURL: "",
  finalURL: "",
  loading: false,
};

const state = () => INITIAL_STATE;

const mutations = {
  setSocialProfileState(state, newSocialProfile) {
    for (const key in newSocialProfile) state[key] = newSocialProfile[key] !== null ? newSocialProfile[key] : "";
  },
  setFormName(state, newFormName) {
    state.formName = newFormName;
  },
  setFormOrganization(state, newFormOrganization) {
    state.formOrganization = newFormOrganization;
  },
  setFormTitle(state, newFormTitle) {
    state.formTitle = newFormTitle;
  },
  setFormEmail(state, newFormEmail) {
    state.formEmail = newFormEmail;
  },
  setFormEmailFlag(state, newFormEmailFlag) {
    state.formEmailFlag = newFormEmailFlag;
  },
  setFormInstagram(state, newFormInstagram) {
    state.formInstagram = newFormInstagram;
  },
  setFormFacebook(state, newFormFacebook) {
    state.formFacebook = newFormFacebook;
  },
  setFormX(state, newFormX) {
    state.formX = newFormX;
  },
  setFormLinkedIn(state, newFormLinkedIn) {
    state.formLinkedIn = newFormLinkedIn;
  },
  setFormPicture(state, newFormPicture) {
    state.formPicture = newFormPicture;
  },
  setForm64Picture(state, newForm64Picture) {
    state.form64Picture = newForm64Picture;
  },
  setSelectedPicture(state, newSelectedPicture) {
    state.selectedPicture = newSelectedPicture;
    console.log(state.selectedPicture);
  },
  setUploadURL(state, newUploadURL) {
    state.uploadURL = newUploadURL;
  },
  setFinalURL(state, newFinalURL) {
    state.finalURL = newFinalURL;
  },
  setLoading(state, isLoading) {
    state.loading = isLoading;
  },
  resetFormData(state) {
    state.formName = state.name;
    state.formOrganization = state.organization;
    state.formTitle = state.title;
    state.formEmail = state.email;
    state.formEmailFlag = state.emailFlag;
    state.formInstagram = state.instagram;
    state.formFacebook = state.facebook;
    state.formX = state.x;
    state.formLinkedIn = state.linkedin;
    state.formPicture = null;
    state.form64Picture = state.profilePicture;
    state.selectedPicture = "";
    state.uploadURL = "";
    state.finalURL = "";
  },
};

const actions = {
  async fetchSocialProfile({ dispatch }) {
    // Will not call backend api if profile already set
    await dispatch("forceFetchSocialProfile");
  },
  async forceFetchSocialProfile({ commit, dispatch, rootState }) {
    commit("setLoading", true);
    try {
      console.log('in forceFetchSocialProfile')
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/socialprofile",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(status)
      console.log(process.env.VUE_APP_API_URL);
      if (status !== 200) throw new Error("Failed in getting Social Profile");
      console.log(data.socialprofile)
      // set normal details
      commit("setSocialProfileState", data.socialprofile);

      // set form data with normal details
      commit("resetFormData");

    } catch (e) {
      dispatch("toast/error", "Failed to load social profile.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  async updateSocialProfile({ commit, dispatch, state, rootState }) {
    console.log("updateSocialProfile here...");
    try {
      const accessToken = rootState.user.token;
      const truncatedProfileURL = state.profilePicture.split('/').slice(-1)[0].split('?')[0]
      const profilePicURL = state.finalURL ? state.finalURL : truncatedProfileURL
      
      if (state.formInstagram.charAt(0) === '@'){
        state.formInstagram = state.formInstagram.slice(1)
      }

      const payload = {
        name: state.formName,
        organization: state.formOrganization,
        title: state.formTitle,
        email: state.formEmail,
        emailFlag: state.formEmailFlag,
        instagram: state.formInstagram,
        facebook: state.formFacebook,
        x: state.formX,
        linkedin: state.formLinkedIn,
        profilePicture: profilePicURL,
      };
      console.log(payload);
      const { status, data } = await axios.request(
        process.env.VUE_APP_API_URL + "/socialprofile/update",
        {
          method: "put",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: payload,
        }
      );
      if (status !== 201) throw new Error("Failed in updating social profile");
      // set normal details
      commit("setSocialProfileState", data);
      // set form data with normal details
      commit("resetFormData");
    } catch (e) {
      dispatch("toast/error", "Failed to load social profile", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  async getUploadURL({ commit, dispatch, rootState }) {
    try {
      console.log('in getUploadURL')
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/socialprofile/upload-url",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting Upload URL");
      console.log(data)

      // truncate the uploadURL
      const finalUrl = data.split('/').slice(-1)[0].split('?')[0]
      console.log(finalUrl)
      // store truncated URL in uploadURL state
      commit("setUploadURL", data);
      commit("setFinalURL", finalUrl);
    } catch (e) {
      dispatch("toast/error", "Failed to get Upload URL.", { root: true });
    } 
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
